/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  // preserve scroll position when navigating back and foth issues of the same newsletter
  // in the minisite

  const pathname = routerProps.location.pathname;
  const prevPathname = prevRouterProps.location.pathname;

  const newsletterPageRegex = /\/n\/.+/;
  const newsletterPageIssueRegex = /\/n\/.+\/\d+/;

  if (
    pathname.match(newsletterPageIssueRegex) &&
    (prevPathname.match(newsletterPageIssueRegex) || prevPathname.match(newsletterPageRegex))
  ) {
    // don't scroll back to top when in going in a newsletter issue page and coming from a newsletter issue page or
    // the newsletter minisite
    return false;
  }

  // scroll back to top
  return true;
};
